

import * as React from 'react';

import Layout from "../../components/layout/layout"
import PageSection from "../../components/layout/page-section"
import PageHeader from "../../components/layout/page-header"

import { useTranslation } from 'react-i18next';

import AwsLogo from "../../img/Powered-By_logo-horiz_RGB.png"
import Diagram from "../../img/appmodernization_architecture.png"

function AppModernizationPage() {

    const { t } = useTranslation(['solutions-appmodernization']);

    return (<Layout>
        <PageSection>
            <PageHeader className="pt-4 pl-14">App Modernization com AWS</PageHeader>
            <div className='flex flex-row items-center justify-center h-full p-4 gap-6'>
                <div className="hidden md:flex flex-col items-center justify-center w-full p-10">
                    <img src={Diagram} alt="Solution Architecture" className='max-w-[500px]' />
                    <p className='text-center pt-4 italic font-light text-sm text-gray-400'>Arquitetura da solução</p>
                </div>
                <div className="flex flex-col w-full justify-center">
                    <h2 className='font-semibold text-xl'>Pegue um atalho para o próximo nível</h2>
                    <p>
                        O desenvolvimento moderno requer uma série de ferramentas e práticas de DevOps.
                        A KXP moderniza seus apps existentes com uso de tecnologias de nuvem que otimizam o
                        tempo da sua equipe e mantem
                    </p>

                    <ul className="list-disc">
                        <li>Menor tempo de deployment</li>
                        <li>Redução problemas de configuração</li>
                        <li>Maior confiabilidade</li>
                        <li>Custos otimizados com uso de escalabilidade horizontal</li>
                        <li>Documentação baseada em código</li>
                    </ul>
                    <p>
                        Para acelerar o processo utilizamos ferramentas como o <strong>BuildPacks</strong>, projeto opensource para identificar
                        e gerar imagem de containers sem a necessidade de Dockerfiles.
                    </p>

                    <p className='pt-2 font-semibold'>
                        Tecnologias: AWS ECS (Containers Serverless), AWS Codeploy (Pipelines), AWS Dynamo & RDS (Modern Database as Service).
                    </p>

                    <div className='mt-3'>
                        <a href="/#contact_us">
                            <button>Entre em contato</button>
                        </a>
                    </div>

                    <div className='mt-4 self-end text-right'>
                        <img src={AwsLogo} alt="AWS logo" className='h-[40px]' />
                    </div>
                </div>
            </div>
        </PageSection>
    </Layout>)
}

export default AppModernizationPage